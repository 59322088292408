<template>
    <div>
        <div class="container pb-5 goUp">
            <SectionTitle title="Videos" /><br />
            <div class="row">
                <div v-for="(video, index) in videos" :key="index" class="col-sm-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="embed-responsive embed-responsive-21by9">
                                <iframe
                                    width="1280"
                                    height="720"
                                    :src="video.link"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <p
                                class="pt-2 text-muted text-center"
                                style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
                                :title="video.tilulo"
                            >
                                {{ video.tilulo }}
                            </p>
                            <button class="btn btn-info" data-toggle="modal" :data-target="`#video-` + index">
                                Ver video
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div
                v-for="(video, index) in videos"
                :key="index"
                class="modal fade bd-example-modal-xl"
                :id="'video-' + index"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe
                                    width="1280"
                                    height="720"
                                    :src="video.link"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { videosMixin } from "@/mixins/Videos";
export default {
    mixins: [videosMixin],
    data() {
        return {
            videos: this.getVideos(),
        };
    },
};
</script>
